const Header = () => {
  return (
      <header className="header">
        <div className="grid grid-cols-3">
            <div className="">
                <div className="d-inline-block ml-4">Characteristics</div>
                <div className="d-inline-block ml-4">Skills</div>
            </div>
            <div className=" text-bold text-color-primary">
                <div className="d-inline-block ml-4">
                    Portfolio
                </div>
            </div>
            <div className="">
                <div className="d-inline-block ml-4">Collaborations</div>
                <div className="d-inline-block ml-4">Individual</div>
                <div className="d-inline-block ml-4">Contact</div>
            </div>
        </div>
      </header>
  );
}

export default Header;