import './TechBox.css'

const TechBox = ({name, link}) => {
    return (
        <a class="tech-box">
          <div class="logo-wrapper">
            <img width="26" height="28" decoding="async" src={link} alt="HTML"/>
          </div>
          <h6 class="tech-name">{name}</h6>
        </a>
    )
}

export default TechBox;