import './ListTechBox.css'
import TechBox from './TechBox';


const ListTechBox = () => {
    return (
      <div class="list-tech-box-container">
          <TechBox name={'HTML'} link={'https://api.iconify.design/skill-icons:html.svg'}/>
          <TechBox name={'HTMX'} link={'https://api.iconify.design/skill-icons:htmx-dark.svg'}/>
          <TechBox name={'CSS'} link={'https://api.iconify.design/skill-icons:css.svg'}/>
          <TechBox name={'SASS'} link={'https://api.iconify.design/skill-icons:sass.svg'}/>
          <TechBox name={'Javascript'} link={'https://api.iconify.design/skill-icons:javascript.svg'}/>
          <TechBox name={'Vue'} link={'https://api.iconify.design/skill-icons:vuejs-dark.svg'}/>
          <TechBox name={'Nuxt'} link={'https://api.iconify.design/skill-icons:nuxtjs-dark.svg'}/>
          <TechBox name={'React'} link={'https://api.iconify.design/skill-icons:react-dark.svg'}/>
          <TechBox name={'Node'} link={'https://api.iconify.design/skill-icons:nodejs-dark.svg'}/>
          <TechBox name={'Golang'} link={'https://api.iconify.design/skill-icons:golang.svg'}/>
          <TechBox name={'Typescript'} link={'https://api.iconify.design/skill-icons:typescript.svg'}/>
      </div>
    )
}

export default ListTechBox;