import Footer from './Footer';
import Header from './Header';
import HeroBanner from './HeroBanner';
import Highlight from './Highlight';
import ListTechBox from './ListTechBox';
import OnProgress from './OnProgress';

function App() {
  return (
    <div className="universal-container">
      <Header/>
      <main>
        <HeroBanner/>
        <Highlight/>
      </main>
        <ListTechBox/>
        <OnProgress/>
      <Footer/>
    </div>
  );
}

export default App;
