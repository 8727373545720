import './Footer.css'

const Footer = () => {
  return (
    <div className="footer-universal">
    <div className="footer-outer">
      <div className="footer-inner">
        <div className="footer-inner-column">
                <img loading="lazy" decoding="async" width="50" height="50" className="footer-block-1-1-1-1"
                src="https://olyve.tanshcreative.com/wp-content/uploads/Logo.png" alt=""  />
          <div className="footer-block-3 mt-4">
            <nav className="footer-block-3-1" aria-label="Menu">
              <ul className="footer-block-3-1-1">
                <li  className="footer-block-3-1-1-x">
                  <a className='footer-block-3-1-1-x-1'>Services</a>
                </li>
                <li className="footer-block-3-1-1-x">
                  <a className='footer-block-3-1-1-x-1' >Works</a>
                </li>
                <li className="footer-block-3-1-1-x">
                  <a className='footer-block-3-1-1-x-1' >About</a>
                </li>
                <li className="footer-block-3-1-1-x">
                  <a className='footer-block-3-1-1-x-1' >Testimonials</a>
                </li>
                <li className="footer-block-3-1-1-x">
                  <a className='footer-block-3-1-1-x-1'>“Say Hello”</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="footer-block-4">
            <div className="footer-block-4-1">
            </div>
          </div>
          <div className="footer-block-5 mt-4">
            <div className="footer-block-5-1">
              <div className="footer-block-5-1-1">
                <ul className="footer-block-3-1-1">
                  <li>
                    <a className="footer-block-5-1-1-1-x" href="#" title="Instagram" target="_blank" rel="nofollow" aria-label="Instagram">
                      <img src='https://api.iconify.design/skill-icons:instagram.svg'/>
                    </a>
                  </li>
                  <li>
                    <a className="footer-block-5-1-1-1-x" href="#" title="Twitter" target="_blank" rel="nofollow" aria-label="Twitter">
                    <img src='https://api.iconify.design/skill-icons:twitter.svg'/>
                    </a>
                  </li>
                  <li>
                    <a className="footer-block-5-1-1-1-x" href="#" title="LinkedIn" target="_blank" rel="nofollow" aria-label="LinkedIn">
                    <img src='https://api.iconify.design/skill-icons:linkedin.svg'/> 
                    </a>
                  </li>
                  <li>
                    <a className="footer-block-5-1-1-1-x" href="#" title="Facebook" target="_blank" rel="nofollow" aria-label="Behance">
                    <img src='https://api.iconify.design/logos:facebook.svg'/> 
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-block-6">
            <div aria-hidden="true" className="footer-block-6-1">
            </div>
          </div>
          <div className="footer-block-7 mt-4">
            <p className="footer-block-7-1">© 2024. Website design and develope by Nhat Han</p>
          </div>
        </div>
      </div>
      </div>
    </div >
  );
}

export default Footer;