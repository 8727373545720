const Box = ({justifyClass, title, icon}) => {
    return (
<div className={'box ' + justifyClass}>
  <div className="content">
    <p className="heading">
        <img src={icon}/>
        <br/>
        { title }
    </p>
    <p className="para">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi laboriosam
      at voluptas minus culpa deserunt delectus sapiente inventore pariatur.
    </p>
  </div>
</div>
    )
}

export default Box;