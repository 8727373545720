import ClipPathBackground from "./ClipPathBackground";
import React, { useState, useEffect } from 'react';
import './HeroBanner.css'

const generateCount = (num) => {
  if (num > 2) {
    return 0;
  } else {
    return num + 1;
  }
};

const Item = ({ num }) => {
  const map = {
    0: <span className="d-inline-block text-intros">Software Engineer</span>,
    1: <span className="d-inline-block text-intros">Website Developer</span>,
    2: <span className="d-inline-block text-intros">UX/UI Designer</span>,
    3: <span className="d-inline-block text-intros">Freelancer</span>,
  };
  return map[num];
};

const HeroBanner = () => {
  const [count, setCount] = useState(0);
  const [animation, setAnimation] = useState('fade-in');

  useEffect(() => {
    const handleTransition = () => {
      // Change count after fade-out completes
      setCount(prevCount => generateCount(prevCount));
      setAnimation('fade-in');
    };
  
    // Set up the interval for fading out and updating the count
    const interval = setInterval(() => {
      setAnimation('fade-out');
      
      // Use setTimeout to ensure fade-out completes before changing count
      setTimeout(handleTransition, 1900); // Match with fade-out duration
    }, 3000); // Interval duration
  
    return () => clearInterval(interval);
  }, [count]); // Now depending on `count` to ensure clean-up works properly

  return (
    <div className="text-center hero-banner-universal">
      <span className="fs-5 text-bold position-relative font-montserrat hero-banner-intro use-select-none">
        <ClipPathBackground/>
        Hello, I am Han
        <br />
        <div className="carer-path text-color-primary">
          <div className={animation}>
            <Item key={count} num={count} />
          </div>
        </div>
      </span>
    </div>
  );
};

export default HeroBanner;