import React, { useState, useEffect } from 'react';
import { PRIMARY_COLOR, SPACING } from './Constants';
import './ClipPathBackground.css'
// Function to generate a random clipPath
const generateRandomClipPath = () => {
  const points = Array.from({ length: 16 }, () =>
    `${Math.random() * 100}% ${Math.random() * 100}%`
  );
  return `polygon(${points.join(', ')})`;
};

const ClipPathBackground = () => {
  const [clipPath, setClipPath] = useState(generateRandomClipPath());

  useEffect(() => {
    const interval = setInterval(() => {
      setClipPath(generateRandomClipPath());
    }, 3000);

    // Cleanup on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ filter: 'blur(64px)' }} className='bg-fixed'>
        <div
        style={{
            clipPath: clipPath,
            width: '100%',
            height: parseInt(SPACING)*30 + 'px',
            backgroundColor: PRIMARY_COLOR + '40',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'clip-path 3s ease',
            position: 'absolute'
        }}
        >
        </div>
    </div>
  );
};

export default ClipPathBackground;