import Box from "./Box";
import IConTech from "./assets/SkillIconsReactDark.svg";
import IConProject from "./assets/SkillIconsLinuxDark.svg";
import IConContribution from "./assets/SkillIconsGithubDark.svg";
const Highlight = () => {
    return (
        <div className="grid grid-cols-3 justify-item-center hight-light-container">
            <Box title='Projects' icon={IConProject} justifyClass='justify-self-end' />
            <Box title='Technologies' icon={IConTech}/>
            <Box title='Contribution' icon={IConContribution} justifyClass='justify-self-start'/>
        </div>
    )
}

export default Highlight;