import './OnProgress.css'

const Card = () => {
  return (
    <div className="op-card">
    <div className="op-container">
      <div className="op-left">
        <div className="op-status-ind"></div>
      </div>
      <div className="op-right">
        <div className="op-text-wrap">
          <p className="op-text-content">
            <a href="#" className="op-text-link">Jane Doe</a> invited you to edit the
            <a href="#" className="op-text-link"> Web Design</a> file.
          </p>
          <p className="op-time">2 hours ago</p>
        </div>
        <div className="op-button-wrap">
          <button className="op-primary-cta">View file</button>
          <button className="op-secondary-cta">Mark as read</button>
        </div>
      </div>
    </div>
  </div>
  )
}

const OnProgress = () => {
  return (
      <div className="on-progress-universal">
        <div className="grid grid-cols-4 justify-item-center hight-light-container  height-progress">
            <div className='col-span-3 w-100'>
                <Card/>
            </div>
            <div className='col-span-1 w-100'>
              <Card/>
            </div>
        </div>
        <div className="grid grid-cols-4 justify-item-center hight-light-container height-progress mt-4">
            <div className='col-span-2 w-100'>
            <Card/>

            </div>
            <div className='col-span-2 w-100'>
            <Card/>

            </div>
        </div>
        <div className="grid grid-cols-4 justify-item-center hight-light-container height-progress mt-4">
            <div className='col-span-1 w-100'>
            <Card/>

            </div>
            <div className='col-span-1 w-100'>
            <Card/>

            </div>
            <div className='col-span-2 w-100'>
            <Card/>

            </div>
        </div>
      </div>
  );
}

export default OnProgress;